import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import ph from './../../assets/PH.png'


function Footer({ onButtonClick }) {
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
    }
};

  const handleButtonClick = () => {
    onButtonClick();
  };
  return (
    <div className="footer-f">
      <div className="view grid-list">

        <div className="footer-brand">
<img src={ph} alt="" className="logo" />

          <p className="company">
            &copy; 2024 YourToothDuchess <br /> All rights reserved.
          </p>

          <ul className="social-list">

            <li>
              <a href="#" className="social-link">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            </li>

            <li>
              <a href="https://www.instagram.com/yourtoothduchess/" className="social-link">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>

            <li>
              <a href="#" className="social-link">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </li>

          </ul>

        </div>

        <ul className="footer-list">

          <li>
            <p className="h4 footer-list-title">Get in Touch</p>
          </li>

          <li>
            <address className="footer-text">
              LAHORE, PAKISTAN
            </address>
          </li>

          <li>
            <a href="mailto:Mahibilal2018@gmail.com" style={{ filter: 'blur(5px)' }} className="footer-link">Mahibilal2018@gmail.com</a>
          </li>

          <li>
            <a href="tel:+92 332 4403033" style={{ filter: 'blur(5px)' }} className="footer-link">+92 332 4403033</a>
          </li>

        </ul>

        <ul className="footer-list">

          <li>
            <p className="h4 footer-list-title">Learn More</p>
          </li>

          <li  className='footer-link' onClick={() => scrollToSection('home')}>Home</li>
                <li className='footer-link' onClick={() => scrollToSection('about-us')}>About us</li>
                <li className='footer-link' onClick={() => scrollToSection('services')}>Services</li>
                <li  className='footer-link' onClick={() => scrollToSection('testimonials')}>Testimonials</li>
        </ul>

        <div className="butn">
         <button onClick={handleButtonClick} className="btn3">CONTACT US</button>
        </div>

      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        className="footer-wave"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 
            58-18 88-18s
            58 18 88 18 
            58-18 88-18 
            58 18 88 18
            v44h-352z"
          />
        </defs>
        <g className="waves">
          <use
            xlinkHref="#gentle-wave"
            x="50"
            y="0"
            fill="#03ffff"
            fillOpacity=".2"
          />
          <use
            xlinkHref="#gentle-wave"
            x="50"
            y="3"
            fill="#03ffff"
            fillOpacity=".5"
          />
          <use
            xlinkHref="#gentle-wave"
            x="50"
            y="6"
            fill="#03ffff"
            fillOpacity=".9"
          />
        </g>
      </svg>
    </div>
  );
}

export default Footer;
