import React, { useState, useEffect } from 'react';
import './About.css';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTooth, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import mg1 from './../../assets/cover2.jpg';

function About() {
    const [expanded, setExpanded] = useState([false, false, false, false]);
    const [isVisible, setIsVisible] = useState({
        subText: false,
        mainText: false,
        listItems: [false, false, false, false],
    });

    const toggleExpand = (index) => {
        setExpanded(expanded.map((item, i) => (i === index ? !item : item)));
    };

    useEffect(() => {
        const handleScroll = () => {
            const subTextElement = document.querySelector('.sub-text-a');
            const mainTextElement = document.querySelector('.main-text-a');
            const listElements = document.querySelectorAll('.header-about');

            const subTextTop = subTextElement.getBoundingClientRect().top;
            const mainTextTop = mainTextElement.getBoundingClientRect().top;

            const newIsVisible = {
                subText: subTextTop < window.innerHeight - 100,
                mainText: mainTextTop < window.innerHeight - 100,
                listItems: isVisible.listItems.map((_, index) => {
                    const elementTop = listElements[index].getBoundingClientRect().top;
                    return elementTop < window.innerHeight - 100;
                }),
            };

            setIsVisible((prev) => {
                return JSON.stringify(prev) === JSON.stringify(newIsVisible) ? prev : newIsVisible;
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isVisible]);

    return (
        <div>
            <div className="about" id="about-us">
                <div className="blur about-blur"></div>
                <div className="left-g">
                    <div className="sideimg">
                        <motion.img   initial={{ opacity: 0, x: -200 }}
                        animate={{ opacity: isVisible.mainText ? 1 : 0, x: isVisible.mainText ? 0 : -200 }}
                        transition={{ duration: 1 }}src={mg1} alt="" />
                    </div>
                </div>
                <div className="right-g">
                    <motion.div
                        className='sub-text-a'
                        initial={{ opacity: 0, x: 200 }}
                        animate={{ opacity: isVisible.subText ? 1 : 0, x: isVisible.subText ? 0 : 200 }}
                        transition={{ duration: 1 }}
                    >
                        <span>WHY CHOOSE US?</span>
                    </motion.div>
                    <motion.div
                        className='main-text-a'
                        initial={{ opacity: 0, x: 200 }}
                        animate={{ opacity: isVisible.mainText ? 1 : 0, x: isVisible.mainText ? 0 : 200 }}
                        transition={{ duration: 1 }}
                    >
                        <span>With <span className='new'>5 years</span> of experience in dental care services, we are committed to providing high-quality dental services tailored to your unique needs. Our focus on excellence and a compassionate approach ensures that every visit is comfortable and positive. Here’s why you should choose us for your dental care:</span>
                    </motion.div>
                    <div>
                        <ul className="custom-bullets">
                            {expanded.map((isExpanded, index) => (
                                <motion.li key={index} initial={{ opacity: 0, y: 50 }} animate={{ opacity: isVisible.listItems[index] ? 1 : 0, y: isVisible.listItems[index] ? 0 : 50 }} transition={{ duration: 1 }}>
                                    <div className="header-about">
                                        <FontAwesomeIcon icon={faTooth} className='toothanim' beat style={{ color: "#19887f" }} />
                                        <span>{index === 0 ? 'Experienced Professionals:' : index === 1 ? 'State-of-the-Art Technology:' : index === 2 ? 'Personalized Care:' : 'Comfort and Convenience:'}</span>
                                        <button className="expand-btn" onClick={() => toggleExpand(index)}>
                                            <FontAwesomeIcon icon={expanded[index] ? faChevronDown : faChevronRight} />
                                        </button>
                                    </div>
                                    {expanded[index] && (
                                        <div className="expand-content">
                                            <span className='innertext'>{
                                                index === 0 ? 'Our team of highly skilled dentists and hygienists brings years of experience and expertise to offer you the best dental solutions.' :
                                                    index === 1 ? 'We use the latest dental technology and techniques to ensure precise and effective treatments.' :
                                                        index === 2 ? 'Every patient receives personalized treatment plans tailored to their specific needs and preferences.' :
                                                            'Our modern, comfortable clinic is designed to make your visit as pleasant and stress-free as possible.'
                                            }</span>
                                        </div>
                                    )}
                                </motion.li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
